<template>
  <v-container fluid class="pl-12 pr-12">
    <ValidationForms ref="forms">
      <v-layout column wrap class="mx-auto">
        <v-card-title class="font-weight-black pl-3 mb-6" style="font-size:32px"
          >{{ $t('link.userInformationAggregate') }}
        </v-card-title>
        <v-row no-gutters class="ml-8">
          <p class="title my-auto">
            {{ $t('label.informationAggregateSalesCode') }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-6" no-gutters>
          <ValidationForm rules="salesCodeCheck">
            <v-flex md4>
              <v-text-field
                v-model="conditions.salesCode"
                :outlined="true"
                hide-details
              />
            </v-flex>
          </ValidationForm>
        </v-row>
        <v-row no-gutters class="ml-8">
          <p class="title my-auto">
            {{ $t('label.informationAggregateAgencyCode') }}
          </p>
        </v-row>
        <v-row class="ml-8 mb-6" no-gutters>
          <ValidationForm rules="agencyCodeCheck">
            <v-flex md4>
              <v-text-field
                v-model="conditions.agencyCode"
                :outlined="true"
                hide-details
              />
            </v-flex>
          </ValidationForm>
        </v-row>

        <v-row no-gutters class="ml-8">
          <p class="title my-auto">{{ $t('label.targetMonthRange') }}</p>
          <v-chip
            class="ma-2 font-weight-black"
            color="next"
            dark
            label
            small
            >{{ $t('label.required') }}</v-chip
          >
        </v-row>
        <v-row class="ml-8 mb-6">
          <v-flex md4>
            <ValidationForm rules="required">
              <VueMonthlyPicker
                :max="maxDate"
                dateFormat="YYYY-MM"
                selectedBackgroundColor="#007bff"
                :value="conditions.month"
                :clearOption="false"
                @selected="monthSelect"
              ></VueMonthlyPicker>
            </ValidationForm>
          </v-flex>
        </v-row>
        <v-row no-gutters class="ml-8">
          <p class="title my-auto">{{ $t('label.role') }}</p>
          <v-chip
            class="ma-2 font-weight-black"
            color="next"
            dark
            label
            small
            >{{ $t('label.required') }}</v-chip
          >
        </v-row>
        <v-row no-gutters class="ml-8 mb-6">
          <ValidationForm rules="selectRequired">
            <v-row
              v-for="(item, index) in roleCheckList"
              :key="index"
              class="ml-0"
            >
              <input
                type="checkbox"
                class="my-auto"
                id="role"
                v-model="conditions.roleType"
                :value="item.value"
              />
              <label class="ml-1">{{ item.text }}</label>
            </v-row>
          </ValidationForm>
        </v-row>
        <v-row>
          <v-btn
            class="font-weight-black ml-8"
            align="center"
            width="30vw"
            max-width="170px"
            color="next"
            style="font-size:20px"
            :dark="!isCsvExport"
            :disabled="isCsvExport"
            @click="csvDownload"
            >{{ $t('button.csvDownload') }}</v-btn
          >
        </v-row>
        <ErrorDialog ref="errorDialog"></ErrorDialog>
      </v-layout>
    </ValidationForms>
    <section></section>
  </v-container>
</template>

<script>
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import VueMonthlyPicker from 'vue-monthly-picker';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { getUserInformationAggregate } from '@/apis/agency/users';
import { Role } from '@/lib/role';
import moment from 'moment';
import { UserInformationAggregateCsv, RoleType } from '@/lib/const';
import { downloadCsv, getCsvFileName } from '@/lib/csv';

export default {
  name: 'UserInformationAggregate',
  components: {
    ValidationForms,
    ValidationForm,
    VueMonthlyPicker,
    ErrorDialog,
  },
  data() {
    return {
      conditions: {
        month: moment()
          .subtract(1, 'months')
          .format('YYYY-MM'),
        agencyCode: null,
        salesCode: null,
        roleType: [],
      },
      maxDate: moment()
        .subtract(1, 'months')
        .format('YYYY-MM'),
      roleCheckList: [
        {
          value: RoleType.HeadAdmin,
          text: this.$t('checkbox.headAdmin'),
        },
        {
          value: RoleType.SalesAdmin,
          text: this.$t('checkbox.salesAdmin'),
        },
        {
          value: RoleType.AgencyAdmin,
          text: this.$t('checkbox.agencyAdmin'),
        },
        {
          value: RoleType.AgencyUser,
          text: this.$t('checkbox.agencyUser'),
        },
      ],
      userList: null,
      isCsvExport: false,
    };
  },

  methods: {
    monthSelect(value) {
      // フォーマットを表示内容と統一
      this.$set(this.conditions, 'month', moment(value).format('YYYY-MM'));
    },

    mapSalesInfo(user, key) {
      // 営業店データのマッピング
      return user.sale // 所属営業店が存在する場合
        ? user.sale[key] // 所属営業店データとする
        : user.agency && user.agency.sale // 所属代理店が存在するかつ、紐づく営業店が存在する場合
        ? user.agency.sale[key] // 所属代理店に紐づく営業店データとする
        : null;
    },

    async csvDownload() {
      // 連打を防止するためCSV出力ボタンを無効化
      this.$set(this, 'isCsvExport', true);
      if (await this.$refs.forms.validate()) {
        this.$set(
          this,
          'userList',
          await getUserInformationAggregate(this.conditions)
        );
        // 対象が0件の場合エラーメッセージを表示してCSV出力を行わない
        if (this.userList.rows.length <= 0) {
          this.$set(this, 'isCsvExport', false);
          this.$refs.errorDialog.open(
            this.$t('title.agency.csvExportError'),
            this.$t('error.notFoundUserList')
          );
          return;
        }
        // 出力データ数が最大行数を上回った場合
        if (this.userList.count > UserInformationAggregateCsv.maxRowCount) {
          this.$refs.errorDialog.open(
            this.$t('title.agency.csvExportError'),
            this.$t('error.invalidCsvMaxRowCount', {
              maxRowCount: UserInformationAggregateCsv.maxRowCount,
            })
          );
          return;
        }

        const headers = {};

        UserInformationAggregateCsv.headers.forEach((header, index) => {
          // ヘッダー名をセットする
          headers[UserInformationAggregateCsv.columns[index]] = header;
        });

        const rows = this.userList.rows.map(user => ({
          loginId: user.loginId,
          userName: user.userName,
          userMail: user.userMail,
          salesCode: this.mapSalesInfo(user, 'salesCode'),
          salesDepartmentName: this.mapSalesInfo(user, 'salesDepartmentName'),
          salesSectionName: this.mapSalesInfo(user, 'salesSectionName'),
          agencyCode: user.agency ? user.agency.agencyCode : '',
          recruiterId: user.recruiterId,
          chargePersonName: user.chargePersonName,
          chargePersonPhoneNumber: user.chargePersonPhoneNumber,
          admin: Role.getAgencyIsAdmin(user.role),
          role: Role.getName(user.role),
          created: moment(user.createdAt).format('YYYY/MM/DD HH:mm'),
          lastLoginDate: user.lastLoginDate
            ? moment(user.lastLoginDate).format('YYYY/MM/DD HH:mm')
            : this.$t('label.notLogined'),
          comment: user.comment,
        }));

        await downloadCsv(
          rows,
          headers,
          getCsvFileName(
            this.$t('link.userInformationAggregate') +
              '_' +
              this.conditions.month
          )
        );
      }
      this.$set(this, 'isCsvExport', false);
    },
  },
};
</script>
